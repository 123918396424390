import React from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  adults: 2,
  children: 0,
  childrenAges: [],
  city: '',
  checkInDate: null,
  checkOutDate: null,
  type: 'all',
  amenities: [],
  hasError: false,
  errorMessage: '',
  errorId: '',
}

function positiveInteger(integerToCheck) {
  return integerToCheck > 0
}

function checkIsString(stringToCheck) {
  return typeof stringToCheck === 'string'
}

function reducer(state, action, websiteToken, apartmentId) {
  switch (action.type) {
    case 'SET_ADULTS': {
      return {
        ...state,
        adults: parseInt(action.value),
      }
    }
    case 'SET_CHILDREN': {
      return {
        ...state,
        children: parseInt(action.value),
      }
    }
    case 'ADD_CHILDREN': {
      return {
        ...state,
        children: state.children + 1,
        childrenAges: state.childrenAges.concat([0]),
      }
    }
    case 'REMOVE_CHILDREN': {
      state.childrenAges.pop()
      return {
        ...state,
        children: state.children - 1,
        childrenAges: state.childrenAges,
      }
    }
    case 'SET_CHILDREN_AGE': {
      var age
      if (positiveInteger(parseInt(action.value))) {
        age = parseInt(action.value)
      } else {
        age = 0
      }
      state.childrenAges.splice(action.position, 1, age)
      return {
        ...state,
        childrenAges: state.childrenAges,
      }
    }
    case 'SET_ALL_CHILDREN_AGES': {
      var ageList = action.value.map((age) => {
        if (positiveInteger(parseInt(age))) {
          return parseInt(age)
        } else {
          return 0
        }
      })
      return {
        ...state,
        childrenAges: ageList,
      }
    }
    case 'SET_CITY': {
      return {
        ...state,
        city: action.value,
      }
    }
    case 'SET_CHECKIN_DATE': {
      return {
        ...state,
        checkInDate: action.value,
      }
    }
    case 'SET_CHECKIN_DATE_AND_RESET_CHECKOUT_DATE': {
      return {
        ...state,
        checkInDate: action.value,
        checkOutDate: initialState.checkOutDate,
      }
    }
    case 'SET_CHECKOUT_DATE': {
      return {
        ...state,
        checkOutDate: action.value,
      }
    }
    case 'SET_CHECKIN_DATE_AND_CHECKOUT_DATE': {
      return {
        ...state,
        checkInDate: action.value.checkInDate,
        checkOutDate: action.value.checkOutDate,
      }
    }
    case 'SET_TYPE': {
      return {
        ...state,
        type: action.value,
      }
    }
    case 'SET_AMENITIES': {
      return {
        ...state,
        amenities: action.value,
      }
    }
    case 'ADD_AMENITY': {
      return {
        ...state,
        amenities: [...state.amenities, action.value],
      }
    }
    case 'REMOVE_AMENITY': {
      return {
        ...state,
        amenities: state.amenities.filter(
          (amenity) => amenity !== action.value,
        ),
      }
    }
    case 'RESET_FILTERS': {
      return {
        ...state,
        type: initialState.type,
        amenities: initialState.amenities,
      }
    }
    case 'SHOW_ERROR': {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return {
        ...state,
        hasError: true,
        errorMessage: action.value.errorMessage,
        errorId: action.value.errorId,
      }
    }
    case 'HIDE_ERROR': {
      return {
        ...state,
        hasError: false,
        errorMessage: initialState.errorMessage,
        errorId: initialState.errorId,
      }
    }
    case 'RESET_GLOBAL_STATE': {
      return {
        initialState,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
