exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-success-js": () => import("./../../../src/pages/booking-success.js" /* webpackChunkName: "component---src-pages-booking-success-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-templates-apartment-booking-page-js": () => import("./../../../src/templates/apartment-booking-page.js" /* webpackChunkName: "component---src-templates-apartment-booking-page-js" */),
  "component---src-templates-apartment-page-js": () => import("./../../../src/templates/apartment-page.js" /* webpackChunkName: "component---src-templates-apartment-page-js" */),
  "component---src-templates-apartments-page-js": () => import("./../../../src/templates/apartments-page.js" /* webpackChunkName: "component---src-templates-apartments-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */)
}

